<template>
    <modal class="payAccountsModel" :isShow="isShow" @keyup.esc="hide">
        <div class="header">应收清账</div>
        <div class="modal-body">
            <div class="title">账户信息</div>
            <div class="li-box">
                <div class="li-flex">
                    <span class="lable-text">应收账户：</span>
                    <div class="from-input">{{data?.Corp_Name}}</div>
                </div>
                <div class="li-flex">
                    <span class="lable-text">应收金额：</span>
                    <div class="from-input">
                        <span class="money">￥{{data?.money}}</span><span class="number">合计：{{data?.number}}笔</span>
                    </div>
                </div>
            </div>
            <div class="title">付款信息</div>
            <div class="li-box">
                <div class="li-flex">
                    <span class="lable-text">付款方式：</span>
                    <select-tree class="from-input" ref="payTree" v-model="Pay_ID" placeholder="请选择付款方式" 
                        :isMultiple="false" :data="treePayTypes" 
                        searchPlaceholder="编号名称模糊检索"
                        is-search
                        :filter-node-method="filterNode"
                        title="付款方式选择" />
                </div>
                <div class="li-flex">
                    <span class="lable-text">实收金额：</span>
                    <div class="from-input"><input-pattern v-model="payMoney" pattern="number" :min="0" :max="Number(data?.money||0)" :dplaces="2"/></div>
                    <el-radio-group class="part-box" v-model="payType" :style="{visibility:isPartShow?'':'hidden'}">      
                        <el-radio :label="0" size="large">部分清账</el-radio>
                        <el-radio :label="1" size="large" v-if="ZRZKPermission">全部清账</el-radio>
                    </el-radio-group>
                </div>
                <div class="li-flex" v-show="isPartShow && payType==1">
                    <span class="lable-text">清账折扣：</span>
                    <div class="from-input"><input type="text" :value="discount" readonly /></div>
                </div>
                <div class="li-flex">
                    <span class="lable-text">备注：</span>
                    <div class="from-input"><input type="text" v-model="remark" maxlength="25"/></div>
                </div>
            </div>
        </div>
        <div class="footer-box">
            <button class="btn" @click="hide()">取消</button>
            <button class="btn btn-confirm" @click="confirm()"> 确认</button>
        </div>
        <!-- 扫码付 -->
        <modal-load :isShow="scanCodePay" isUnload>
            <scan-code-pay-model :isShow="scanCodePay" @close="scanCodePay=false" 
                @updateTime="(time)=>{updateTime(time,scanCodePayData)}" 
                @success="scanCodePaySuccess()"
                :data="scanCodePayData"></scan-code-pay-model>
        </modal-load>
        <!--会员支付-->
        <modal-load :isShow="memberPayShow" isUnload>
            <member-payment-model :isShow="memberPayShow" @close="memberPayShow=false" 
                @updateTime="(time)=>{ updateTime(time,memberPayData)}" 
                @success="memberPaySuccess()"
                :data="memberPayData"
            ></member-payment-model>
        </modal-load>
    </modal>
</template>

<script>

import scanCodePayModel from '../scanCodePayModel/scanCodePayModel'
import memberPaymentModel from '../memberPaymentModel/memberPaymentModel'
// 应收清账
export default {
    name:'payAccountsModel',
    components:{
        scanCodePayModel,
        memberPaymentModel
    },
    emits:["close","confirm","updateTime"],
    props:{
        isShow:Boolean,
        data:{
            typeof:Object,
            default:null,
        },
        //清账折扣权限
        ZRZKPermission:{
            type:Boolean,
            default:false
        }
    },
    data(){
        return{
            /**付款方式数据 */
            payTypes:[],
            Pay_ID:"",
            //实收金额
            payMoney:"",
            //备注
            remark:'',
            /**0：部分清账 1：全部清账 */
            payType:0,
            /**扫码付款 */
            scanCodePay:false,
            /**扫码付款 传入的数据*/
            scanCodePayData:null,
            /**会员支付 */
            memberPayShow:false,
            /**会员支付 传入的数据*/
            memberPayData:null
        }
    },
    computed:{
        /**选择 付款方式下拉数据*/
        treePayTypes(){
            let tree=[];
            this.payTypes?.forEach(it=>{
                let children=[];
                it.PayTypes.forEach(it=>{//1现金支付 2 普通支付 801010301 聚合支付  801010401 正品CRM3.0会员支付
                    if(it.INTERFACE_TYPE=="1" || it.INTERFACE_TYPE=="2" || it.INTERFACE_TYPE=="801010301" || it.INTERFACE_TYPE=="801010401"){
                        children.push(Object.assign({
                            id:it.PAY_ID,
                            name:it.PAY_CODE+"-"+it.PAY_NAME,
                        },it))
                    } 
                })
                if(children.length>0){
                    tree.push({
                        id:"kind"+it.PAYKIND_ID,
                        name:it.PAYKIND_CODE+"-"+it.PAYKIND_NAME,
                        children:children
                    });
                }
                
            })
            return tree;
        },
        /**折扣 */
        discount(){
            let money=0;
            if(this.data?.money>0 && this.payMoney>=0){
                money=Number(this.data?.money)- Number(this.payMoney);
            }
            return Number(money.toFixed(4));
        },
        /**是否显示 */
        isPartShow(){
            if(this.payMoney!=null && this.payMoney!=""){
                if(Number(this.payMoney)< Number(this.data?.money)){
                    return true;
                }
            }
            return false;
        }
    },
    watch:{
        isShow(newValue){
            if(newValue){
                this.Pay_ID="";
                this.payMoney=Number(this.data?.money||0)
                this.remark="";
                this.payType=0;
                this.loadData();
            }
        },
        isPartShow(){
            if(this.isPartShow){
                this.payType=0;
            }
        }
    },
    mounted(){
        this.loadData();
        this.payMoney=Number(this.data?.money||0);
    },
    
    methods:{
        hide(){
            this.$emit("close");
        },
        //加载数据
        loadData(){
            if(this.payTypes?.length>0){
                return;
            }
            const loading = this.$loading({
                text: "数据加载中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });

            /* 获取支付方式 */
            this.$cacheData.PayType().then((d)=>{
                loading.close();
                this.payTypes=d;
                console.log(d)
            }).catch(e=>{
                this.$alert("付款方式数据加载失败"+e.message, "提示", {confirmButtonText: "确定"});
            })
        },
        /**搜索 */
        filterNode(value, data){
            if (!value) return true;
            value=value.toLocaleUpperCase();
            return data.PAY_CODE?.toLocaleUpperCase().includes(value) || data.PAY_NAME?.toLocaleUpperCase().includes(value);
        },
        /**点击确认 */
        confirm(){
            if(!this.Pay_ID){
                this.$message.warning("请先选择付款方式!");
                return;
            }

            if(this.payMoney==""){
                this.$message.warning("请先输入实收金额!");
                return;
            }

            if(!(this.payMoney>0)){
                this.$message.warning("实收金额必须大于0!");
                return;
            }
            let type=this.$refs.payTree?.val?.INTERFACE_TYPE;//付款方式类型
            if(type=="1" || type=="2"){//现金或普通支付方式
                this.payARCorpConsumes();
            }else if(type=="801010301"){//聚合支付
                let Corp_ConsumeIDList=this.data?.Corp_ConsumeIDList?.map(it=>it.Corp_ConsumeID);
                this.scanCodePayData={
                    Corp_AutoID:this.data?.Corp_AutoID,//原账户ID
                    money:this.data?.money,//总金额
                    payMoney:this.payMoney,//待支付金额
                    Pay_ID:this.Pay_ID,//支付方式id
                    LastUpdated_Time:this.data?.LastUpdated_Time,//原账户最后更新时间
                    payType:this.payType,//0：部分清账 1：全部清账
                    remark:this.remark,//备注
                    Recharge_Type:0,//0：来款清账 1：预付款充值
                    Corp_ConsumeIDList:Corp_ConsumeIDList,//明细主键List
                }
                this.scanCodePay=true;
            }else if(type=="801010401"){//正品CRM3.0会员支付
                let Corp_ConsumeIDList=this.data?.Corp_ConsumeIDList?.map(it=>it.Corp_ConsumeID);
                this.memberPayData={
                    Corp_AutoID:this.data?.Corp_AutoID,//原账户ID
                    money:this.data?.money,//总金额
                    payMoney:this.payMoney,//待支付金额
                    Pay_ID:this.Pay_ID,//支付方式id
                    LastUpdated_Time:this.data?.LastUpdated_Time,//原账户最后更新时间
                    payType:this.payType,//0：部分清账 1：全部清账
                    remark:this.remark,//备注
                    Recharge_Type:0,//0：来款清账 1：预付款充值
                    Corp_ConsumeIDList:Corp_ConsumeIDList,//明细主键List
                }
                this.memberPayShow=true;
            }
        },
        /**付款请求*/
        payARCorpConsumes(){
            const loading = this.$loading({
                text: "数据保存中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let userInfo= this.$auth.getUserInfo();
            let Corp_ConsumeIDList=this.data?.Corp_ConsumeIDList?.map(it=>it.Corp_ConsumeID);
            this.$httpAES.post("Bestech.CloudPos.PayARCorpPreview",{
                User_ID:userInfo.User_ID,
                Operator_Name:userInfo?.Login_Name,
                Corp_AutoID:this.data?.Corp_AutoID,//原账户ID
                Pay_ID:this.Pay_ID,//支付方式id
                PaymentType:this.$refs.payTree?.val?.INTERFACE_TYPE,//付款方式类型
                Corp_SumMoney:this.data?.money,//总金额
                Pay_Money:this.payMoney,//支付金额
                Pay_Type:this.payType,//0：部分清账 1：全部清账
                Remark:this.remark,
                Recharge_Type:0,//0：来款清账 1：预付款充值
                LastUpdated_Time:this.data?.LastUpdated_Time,//原账户最后更新时间
                //明细主键List
                Corp_ConsumeIDList:Corp_ConsumeIDList
            }).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.$message.success("清账成功");
                    this.$emit('updateTime',d.ResponseBody?.Corp_LastUpdateTime)
                    this.$emit("confirm");
                    if(d.ResponseBody?.PrintsInfo){//打印
                        setTimeout(()=>{
                            this.$webBrowser.posPrint(d.ResponseBody.PrintsInfo);
                        },100);
                    }
                }else{
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('清账失败：'+e.message);
                console.log('清账失败：',e);
            })
        },
        /**付款成功 */
        scanCodePaySuccess(){
            this.scanCodePay=false;
            this.$emit("confirm");
        },
        /**会员支付成功 */
        memberPaySuccess(){
            this.memberPayShow=false;
            this.$emit("confirm");
        },
        /**更新时间戳 */
        updateTime(time,data){
            if(data){
                data.LastUpdated_Time=time;
            }
            this.$emit('updateTime',time);
        }
    }
}
</script>

<style lang="scss">
@import './payAccountsModel.scss'
</style>